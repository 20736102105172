import { IPropsNFButtonData } from 'components/common/NFButton';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { IRelatedElementsCarouselData } from 'components/RelatedElementsCarousel';
import { getDOMRectById } from 'utils/browser';
import ELEMENTS from 'utils/constants';
import { DEFAULT_ITEM_HEIGHT } from './constants';

const getButtonStyles = (
  isVisible: boolean,
  btnData: IPropsNFButtonData
): IPropsNFButtonData['btnStyles'] | undefined => {
  return btnData?.btnStyles
    ? {
        ...btnData.btnStyles,
        ...(isVisible
          ? {
              inactiveBgColor: btnData.btnStyles.activeBgColor,
              inactiveBorderColor: btnData.btnStyles.activeBorderColor,
              inactiveTextColor: btnData.btnStyles.activeTextColor,
            }
          : {
              activeBgColor: btnData.btnStyles.activeBgColor,
              activeBorderColor: btnData.btnStyles.activeBorderColor,
              activeTextColor: btnData.btnStyles.activeTextColor,
            }),
      }
    : undefined;
};

const getRelatedElementsListProps = (
  relatedElements: {
    properties: RelatedElements.IUmbracoRelatedElementsListMainProperties;
  },
  carouselControls: AppCarousel.ICarouselControls
): IRelatedElementsCarouselData | undefined => {
  const relatedElementsListData = relatedElements?.properties;

  return relatedElementsListData
    ? {
        title: relatedElementsListData.relatedElementsListTitle,
        CTABtn: relatedElementsListData.relatedElementsListCTAButton?.length
          ? {
              ...parseNFButton(relatedElementsListData.relatedElementsListCTAButton[0].properties),
            }
          : undefined,
        items: relatedElementsListData.relatedElementsListItems,
        submenuItemAnchor:
          relatedElementsListData.relatedElementsListSubmenuLink?.[0]?.submenuItemAnchor,
        closeButtonAriaLabel: relatedElementsListData.relatedElementsListCTACloseButtonAriaLabel,
        closeButtonTitle: relatedElementsListData.relatedElementsListCTACloseButtonTitle,
        carouselControls,
      }
    : undefined;
};

const resizeRelatedElementsList = (
  isMobile: boolean | null,
  setShowScrollOnRelatedElements: (value: boolean) => void,
  popupListNumber?: number
) => {
  const wrapper = document.getElementById('related-elements-carousel-wrapper');
  if (!wrapper) {
    return;
  }
  const windowHeight = window.innerHeight;
  const headerData = getDOMRectById(ELEMENTS.HEADER_ID);
  const actionBlockData = getDOMRectById('simple-popup-action-block');
  const listTitleData = getDOMRectById('related-elements-carousel-title');

  const blockHeight =
    windowHeight -
    ((headerData?.height || 0) + (listTitleData?.height || 0) + (actionBlockData?.height || 0));

  wrapper.style.height = `${blockHeight}px`;

  if (popupListNumber && isMobile) {
    const itemsToShow = Math.floor(blockHeight / DEFAULT_ITEM_HEIGHT);
    setShowScrollOnRelatedElements(popupListNumber > itemsToShow);
  }
};

const relatedElementsCarouselHelpers = {
  getButtonStyles,
  getRelatedElementsListProps,
  resizeRelatedElementsList,
};

export default relatedElementsCarouselHelpers;
